import React, { Component} from 'react';
import { Container, Col, Row, Card, Button, Navbar, Nav, Badge} from 'react-bootstrap';
import  {PanToolOutlined, DoneAllOutlined, MarkunreadOutlined, Storefront, EmojiEmotionsOutlined, ArrowRightAlt, CloseOutlined, Call, Email, Public, Link, LocalOffer, ArrowBack, MoreVert}  from '@mui/icons-material';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import ShopSelect from "../../components/Shopselect";
import axios from 'axios';
import TimeAgo from 'react-timeago'
import socketIOClient from "socket.io-client";
import ReactTooltip from 'react-tooltip';
import {withRouter } from 'react-router-dom';
import config from '../../config'
const TP_API_URL = config.TP_API_URL;
const SOCKET_URL = config.SOCKET_URL;

let gcl = axios.CancelToken.source();
let vc = axios.CancelToken.source();
class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
           showicon: false,
           chaticons: "",
           smiley: <EmojiEmotionsOutlined />,
           msg:"",
           conversation_list:[],
           shop:0,
           search:"",
           cid:0,
           sms:[],
           cart:[],
           dnc:0,
           loadingview:1,
           loadingText:"Select a chat to start messaging.",
           itemref:0,
           cpage:1,
           pages:{},
           c_type:1,
           unread_abd:0,
           unread_pp:0

        };

        const socket = socketIOClient(SOCKET_URL);
        socket.on("Incoming_sms", data => {
            this.update_incoming_msg(data);
        });

        this.handleEmojiSelect = this.handleEmojiSelect.bind(this);
    }

    

    update_incoming_msg = (data) => {
        if(data.id===this.state.cid){
            let newsms = {
                "sms_from": data.sms_from,
                "sms_to": data.sms_to,
                "sms_body":data.sms,
                "sms_received": data.sms_received,
                "created_on": data.created_on
            };
            
            const smsarray = this.state.sms;
            smsarray.push(newsms);

            this.setState({
                sms:smsarray
            },
            () => {
                this.scrollToBottom();
            });
        }

        this.get_conversation_list(this.state.cpage, this.state.shop, 1, "", 1);
    }

    setShop = (id) =>{
        if(this.state.shop!==id) {
            this.setState({
                shop:id,
                conversation_list:[],
                cid:0,
                loadingview:1,
                loadingText:"Select a chat to start messaging.",
                cpage:1
            }, this.get_conversation_list(1, id, 0, "", 0));
        }
    }

    addEmoji = (i) =>{
        this.setState({
            msg:this.state.msg+i.native
        });
    }

    showIcon = (e) => {
        if(this.state.showicon===false){
            this.setState({
                showicon: true,
                chaticons: "show",
                smiley: <CloseOutlined />
            });
        } else {
            this.setState({
                showicon: false,
                chaticons: "",
                smiley: <EmojiEmotionsOutlined />
            });
        }
    }

    textButton = (e) => {
        let txt = e.target.value;
        let omsg = this.state.msg;
        this.setState({
            msg:omsg+txt
        });

        const input = document.getElementById("sms_body");
        input.value = omsg+txt;
        input.style.height = 'inherit';
        input.style.height = `${input.scrollHeight}px`;
    }


    NewCode = (e) => {
        let id = e.target.value;
        e.target.disabled = true;

        this.setState({
            loadingview:1,
            loadingText:"Generating new discount code and urls :) ....",
        });

        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }

        axios.post(TP_API_URL+'agent/conversation/create-discount', {
            id:id,
            cart_id:this.state.cart.abandoned_cart_id,
            shop_id:this.state.cart.shop_id,
            checkout_id:this.state.cart.checkout_id
        }, ax_headers).then((res) => {
            this.view_conversation(e, id, this.state.itemref);
            e.target.disabled = false;
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    auto_grow = (e) =>{
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
        this.setState({
            msg: e.target.value
        });
    }


    get_conversation_list = (page, shop, wildcard, search, reset) => {
        if (gcl) { gcl.cancel();}
        gcl = axios.CancelToken.source();
        const tp_agent_token = localStorage.getItem("tp_agent_token");
        axios.get(TP_API_URL+'agent/conversation/list', {
            params:{
                page:page,
                shopid:shop,
                wildcard:wildcard,
                search:this.state.search,
                c_type:this.state.c_type
            },
            headers:{token: tp_agent_token},
            cancelToken: gcl.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                if(reset===1){
                    this.setState({
                        conversation_list: response.data,
                        unread_pp:response.unread_pp,
                        unread_abd:response.unread_abd
                    });
                } else {
                    let narray = this.state.conversation_list.concat(response.data);
                    this.setState({
                        conversation_list: narray,
                        cpage:this.state.cpage+1,
                        unread_pp:response.unread_pp,
                        unread_abd:response.unread_abd
                    });
                }
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }



    scroll_conversation_list = (e) => {
        var ht = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight));
        if (ht <= 1) {
            this.get_conversation_list(this.state.cpage, this.state.shop, 0, "", 0);
        }
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ });
    }

    close_conversation = (e) => {
        document.body.classList.remove('show_conversation');
    }

    view_data = (e) => {
        document.body.classList.add('view_data');
    }

    close_data = (e) => {
        document.body.classList.remove('view_data');
    }

    view_conversation = (e, id, i) => {
        document.body.classList.add('show_conversation');

        this.setState({
            cid: id,
            sms:[],
            cart:[],
            loadingview:1,
            loadingText:"Loading...",
            itemref:i
        });

        if (vc) { vc.cancel();}
        vc = axios.CancelToken.source();
        const tp_agent_token = localStorage.getItem("tp_agent_token");
        axios.get(TP_API_URL+'agent/conversation/view', {
            params:{
                id:id
            },
            headers:{token: tp_agent_token},
            cancelToken: vc.token
        })
        .then((res) => {
            const response = res.data;
            this.setState({
                sms: response.sms,
                cart:response.cart,
                loadingview:0,
                dnc:response.cart.dnc,
                msg:"",
                pages: (() => {
                    try {
                        return JSON.parse(response.cart.pages);
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                        // Return a default fallback object
                        return {
                            in_return_policy: 0,
                            in_exchange_policy: 0,
                            in_faq: 0,
                            in_terms: 0,
                            in_shipping_policy: 0,
                            return_policy: "NO RETURN POLICY PAGE",
                            exchange_policy: "NO EXCHANGE POLICY PAGE",
                            faq: "NO FAQ PAGE",
                            terms: "NO TERMS PAGE",
                            shipping_policy: "NO SHIPPING POLICY PAGE",
                        };
                    }
                })(),
            },
            () => {
                this.scrollToBottom();
            });
        })
        .catch((e) => {
            console.log('Error viewing message:', e.message);
        });
    }

    initials = (name) =>{
        var sh = name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
        return sh;
    }
    
    markread = (id, i) => {
        
        const narray = this.state.conversation_list;
        narray[i].sms_unread = 0;
        let unread_abd = this.state.unread_abd;
        let unread_pp = this.state.unread_pp;
        if(this.state.c_type===1) {
            unread_abd=unread_abd-1;
        }

        if(this.state.c_type===2) {
            unread_pp=unread_pp-1;
        }

        this.setState({
            conversation_list:narray,
            unread_abd:unread_abd,
            unread_pp:unread_pp
        });

        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }

        axios.post(TP_API_URL+'agent/conversation/mark-read', {
                id:id
        }, ax_headers).then((res) => {
            const response = res.data;
            console.log('response', response)
        })
        .catch((e) => {
            console.log(e.message);
        });
    }

    markdnc = (id, i) => {
        const narray = this.state.conversation_list;
        narray[i].dnc = 1;
        this.setState({
            dnc: 1,
            conversation_list:narray
        });
        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }

        axios.post(TP_API_URL+'agent/conversation/dnc', {
                id:id
        }, ax_headers).then((res) => {
            //const response = res.data;
            this.view_conversation("e", id, this.state.itemref);
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });

    }

    unmarkdnc = (id, i) => {
        const narray = this.state.conversation_list;
        narray[i].dnc = 0;
        this.setState({
            dnc: 0,
            conversation_list:narray
        });
        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }

        axios.post(TP_API_URL+'agent/conversation/unmark-dnc', {
                id:id
        }, ax_headers).then((res) => {
            //const response = res.data;
            this.view_conversation("e", id, this.state.itemref);
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });

    }

    markunread = (id, i) => {
        const narray = this.state.conversation_list;
        narray[i].sms_unread = 1;

        let unread_abd = this.state.unread_abd;
        let unread_pp = this.state.unread_pp;
        if(this.state.c_type===1) {
            unread_abd=unread_abd+1;
        }

        if(this.state.c_type===2) {
            unread_pp=unread_pp+1;
        }

        this.setState({
            conversation_list:narray,
            unread_abd:unread_abd,
            unread_pp:unread_pp
        });

        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }

        axios.post(TP_API_URL+'agent/conversation/mark-unread', {
                id:id
        }, ax_headers).then((res) => {
            //const response = res.data;
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    sendsms = (i, id, cart_id, msgto, msgfrom, shop_id) => {
        var msg = this.state.msg;
        if(msg!==''){
            const narray = this.state.conversation_list;
            narray[i].sms_unread = 0;

            let newsms = {
                "sms_from": msgfrom,
                "sms_to": msgto,
                "sms_body":msg,
                "sms_received": 0,
                "created_on": new Date()
            };

            const smsarray = this.state.sms;
            smsarray.push(newsms);

            this.setState({
                sms:smsarray,
                msg:"",
                conversation_list:narray
            },
            () => {
                this.scrollToBottom();
            });

            const input = document.getElementById("sms_body");
            input.style.height = 'inherit';


            const tp_agent_token = localStorage.getItem("tp_agent_token");
            const ax_headers = {
                headers: {
                    token: tp_agent_token
                }
            }

            axios.post(TP_API_URL+'agent/sms/send', {
                    id : id,
                    cart_id : cart_id,
                    sms_to : msgto,
                    sms_from : msgfrom,
                    sms_body : msg,
                    shop_id:shop_id
            }, ax_headers)
            .then((res) => {
               // const response = res.data;
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });

        }

    }

    format_msg = (msg) => {
        let newmsg = msg.replace(/\\r\\n/g, '\n');
        newmsg = newmsg.replace(/\\n/g, '\n');
        //newmsg = newmsg.replace(/\\/g, '');
        return newmsg;
    }

    conversation_type = (type) => {
        this.setState({
            c_type:type,
            conversation_list:[],
            loadingview:1,
            loadingText:"Select a chat to start messaging."
        },
        () => {
            this.get_conversation_list(1, this.state.shop, 0, "", 1);
        });
        
    }

    componentDidMount(){
        this.get_conversation_list(this.state.cpage, this.state.shop, 0, "", 0);
    }

    handleEmojiSelect = (emoji) => {
      const textarea = document.getElementById('sms_body');
      
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = textarea.value;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        const newText = before + emoji.native + after;
        
        this.setState({ msg: newText }, () => {
          textarea.focus();
          textarea.setSelectionRange(start + emoji.native.length, start + emoji.native.length);
        });
      } else {
        // Fallback if textarea is not found
        this.setState(prevState => ({
          msg: prevState.msg + emoji.native
        }));
      }
    }

    handleMessageChange = (event) => {
      this.setState({ msg: event.target.value });
    }

    render () {
        const { conversation_list, sms, cart } = this.state;
        return (
            
            <>
            <ReactTooltip />
            <Container fluid>
                    <Row>
                        <Col md={3} className="leftbar">
                            <Row className="titlebar align-items-center">
                                <Col xs={4}>Chat</Col>
                                <Col xs={8} className='py-1'><ShopSelect shop={this.state.shop} onCallback={this.setShop}></ShopSelect></Col>
                                <Col xs={12}>
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button className={this.state.c_type===1 ? "nav-link active" : "nav-link"} onClick={e => {this.conversation_type(1)}}>Abandoned Carts {this.state.unread_abd>0 ? <Badge className='rounded-pill bg-success'>{this.state.unread_abd}</Badge> : ""}</button>
                                        </li>
                                        {/* <li className="nav-item">
                                            <button className={this.state.c_type===2 ? "nav-link active" : "nav-link"} onClick={e => {this.conversation_type(2)}}>Post Purchase {this.state.unread_pp>0 ? <Badge className='rounded-pill bg-success'>{this.state.unread_pp}</Badge> : ""}</button>
                                        </li> */}
                                    </ul>
                                </Col>
                            </Row>
                            
                            <ul className="contacts-list chatheads" id="chatContactTab" data-chat-list="" onScroll={this.scroll_conversation_list}>
                                {
                                    conversation_list.map((item, i) => ( 
                                        <li className={item.id===this.state.cid ? "chathead contacts-item active" : "chathead contacts-item"} key={item.id} id={item.id} onClick={ e => this.view_conversation(e, item.id, i) }>
                                            <div className={item.order_recovered===1 ? "contacts-link bg-recovered" : "contacts-link"} >
                                                {
                                                    item.dnc===1 ? <div className="avatar text-light bg-danger">
                                                        <PanToolOutlined />
                                                    </div> 
                                                    : item.sms_unread>0 ? <div className="avatar text-light bg-success">
                                                        <span className="ihw-18 font-weight-bold">{item.sms_unread}</span>
                                                    </div> 
                                                    : <div className="avatar text-light bg-secondary">
                                                       <DoneAllOutlined />
                                                    </div>
                                                }
                                                
                                                <div className="contacts-content">
                                                    <div className="contacts-info">
                                                        <h6 className="chat-name">{item.cart_name}</h6>
                                                        <div className="chat-time">
                                                            <TimeAgo date={item.updated_on} />
                                                            {

                                                               // item.order_recovered===1 ? <span className="text-success ml-5"><CheckCircle /></span> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="contacts-texts">
                                                        <p className="text-truncate">{item.sms_recent_text}</p>
                                                        <span className="badge bg-secondary">{item.shop_name}</span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Col>
                        <Col md={9} className="position-relative rmbar">
                            {this.state.loadingview===1 ? <div className="loading" onClick={e => {this.close_conversation(e)}}>{this.state.loadingText}</div> : "" }
                            <Row>
                                <Col md={8} className="contentbar chatcontent">
                                    <div className="chatheader">
                                        <Row>
                                            <Col xs="auto" className="title-icon d-none d-md-block">
                                                <Storefront />
                                            </Col>
                                            <Col>
                                                <Navbar>
                                                    <Container className="px-0">
                                                        <Navbar.Collapse id="responsive-navbar-nav">
                                                            <Nav className="me-auto">
                                                                <div className="avatar-details d-flex" onClick={e => {this.close_conversation(e)}}>
                                                                    <div className="d-block d-md-none"><ArrowBack /></div>
                                                                    <div>
                                                                        <h3>{cart.cart_name}</h3>
                                                                        <p>{cart.shop_name}</p>
                                                                    </div>
                                                                </div>
                                                            </Nav>
                                                            <Nav>
                                                                <Nav.Link className="btn btn-sm btn-outline-success mr-5" onClick={() => {this.markread(cart.id, this.state.itemref)}} >
                                                                    <DoneAllOutlined/> <span className="d-none d-md-inline-block">Mark Read</span>
                                                                </Nav.Link>
                                                                <Nav.Link className="btn btn-sm btn-outline-primary mr-5" onClick={() => {this.markunread(cart.id, this.state.itemref)}} >
                                                                    <MarkunreadOutlined /> <span className="d-none d-md-inline-block">Mark Unread</span>
                                                                </Nav.Link>
                                                                {this.state.dnc===1 ?
                                                                <Nav.Link className="btn btn-sm mr-5 btn-danger text-light" onClick={() => {this.unmarkdnc(cart.id, this.state.itemref)}} >
                                                                    <PanToolOutlined /> <span className="d-none d-md-inline-block">DNC</span>
                                                                </Nav.Link>

                                                                :

                                                                <Nav.Link className="btn btn-sm mr-5 btn-outline-danger" onClick={() => {this.markdnc(cart.id, this.state.itemref)}} >
                                                                    <PanToolOutlined /> <span className="d-none d-md-inline-block">DNC</span>
                                                                </Nav.Link>

                                                                }

                                                                <Nav.Link className="px-0 d-block d-md-none" onClick={e => {this.view_data(e)}} >
                                                                    <MoreVert />
                                                                </Nav.Link>
                                                                {/*
                                                                <Dropdown align="end">
                                                                    <Dropdown.Toggle id="dropdown-basic" varient="primary">
                                                                        <GroupAdd />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.ItemText >Assign to</Dropdown.ItemText>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item href="#action/3.1">Action</Dropdown.Item>
                                                                        <Dropdown.Item href="#action/3.2">Another action</Dropdown.Item>
                                                                        <Dropdown.Item href="#action/3.3">Something</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                */}
                                                            </Nav>
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="chatbody">
                                        <Row>
                                            <Col className="chat-content p-10" id="messageBody">
                                                {
                                                    sms.map((msg) => ( 
                                                        <div className={msg.sms_received===1 ? "message" : "message self"}>
                                                            <div className="message-wrapper">
                                                                <div className="message-content ">
                                                                    { 
                                                                        (msg.sms_type==='image/jpeg' || msg.sms_type==='image/gif' || msg.sms_type==='image/png') ?
                                                                            <span dangerouslySetInnerHTML={{ __html: msg.sms_body }}></span>
                                                                        :
                                                                            <span style={{whiteSpace: "pre-wrap"}}>{this.format_msg(msg.sms_body)}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="message-options">
                                                                <div className="avatar avatar-sm">
                                                                    <span>{msg.sms_received===1 ? this.initials(cart.cart_name) : "A"} </span>
                                                                </div>
                                                                <span className="message-date"><TimeAgo date={msg.created_on} /></span>
                                                            </div>
                                                        </div>
                                                    )) 
                                                }
                                                <div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}> </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    { cart.dnc === 1 ? "" : 
                                    <div className="chatfooter">
                                        <Row>
                                            <Col xs={12} className={'p-0 chaticons ' + this.state.chaticons}>
                                                <Picker data={data} onEmojiSelect={this.handleEmojiSelect} />
                                            </Col>
                                            <Col xs="auto" id="smiley" onClick={this.showIcon}>
                                                {this.state.smiley}
                                            </Col>
                                            <Col className='p-0'>
                                                <div className="input-group">
                                                    <textarea 
                                                      rows="1" 
                                                      onChange={this.handleMessageChange} 
                                                      className="form-control transparent-bg no-resize hide-scrollbar" 
                                                      placeholder="Type a message" 
                                                      id="sms_body" 
                                                      value={this.state.msg}
                                                    ></textarea>
                                                </div>
                                                
                                            </Col>
                                            <Col xs="auto">
                                                <button className="btn btn-primary btn-icon rounded-circle text-light mb-1" id="send_sms" type="button" onClick={() => {this.sendsms(this.state.itemref, cart.id, cart.abandoned_cart_id, cart.sms_to, cart.sms_from, cart.shop_id)}}>
                                                    <ArrowRightAlt />
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {cart.cart_discount_code_type===1 ?
                                                <>
                                                    <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Okay! I just made you a "+cart.discount_value+"% off discount code and applied it to your cart here:"+cart.short_url_discount}>
                                                        Cart Link
                                                    </Button>
                                                </> : "" }

                                                {cart.cart_discount_code_type===2 ?
                                                <>
                                                    <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Okay! I just made you a free shipping discount code and applied it to your cart here:"+cart.short_url_discount}>
                                                        Cart Link
                                                    </Button>
                                                </> : "" }

                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Your discount code is "+cart.cart_discount_code+" ! Let me know if you have any questions! 😊"}>
                                                        Discount Code
                                                </Button>

                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value="Of course! Let me know if I can assist you with anything else. Happy shopping!">
                                                    Of course
                                                </Button>

                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value="Enjoy your purchase! 🙂">
                                                    Enjoy
                                                </Button>

                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Oh sorry it looks like you already used our best available discount. But here’s a discount code for your next purchase:"+cart.cart_discount_code}>
                                                    Next purchase
                                                </Button>

                                                {cart.free_shipping_threshold>0 ?
                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={cart.fsb}>
                                                    Free shipping
                                                </Button>
                                                : "" }

                                                {cart.customer_service_email ?
                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Please email us at "+cart.shop_email+" using the email you ordered with and we'll be able to help you."}>
                                                    Email us
                                                </Button>
                                                : "" }

                                                <Button variant="outline-primary" type="button" onClick={e => {this.textButton(e)}} className="btn-sm mr-5 mb-5" value={"Oh sorry I didn't realize you already had a discount code."}>
                                                    Sorry
                                                </Button>

                                            </Col>
                                        </Row>
                                    </div>
                                    }
                                </Col>
                                <Col md={4} className="rightbar">
                                    <div className="d-block d-md-none my-3" onClick={e => {this.close_data(e)}}><ArrowBack /> Back</div>
                                    <Card className="mt-15">
                                        <Card.Body>
                                            <Card.Title>{cart.cart_name}</Card.Title>
                                            <Card.Text>
                                                <Call /> {cart.cart_phone} <br />
                                                <Email /> {cart.cart_email} <br />
                                                <Public /> {cart.cart_country} - {cart.cart_zip} - {cart.timezone}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                    <Card className="mt-15">
                                        <Card.Body>
                                            <Card.Title>${cart.order_total}</Card.Title>
                                            <Card.Text>
                                                <Link /> <a href={cart.cart_url} target="_blank" rel="noreferrer">Link to cart</a><br />
                                                {this.state.c_type===1 ? <><span data-tip="Link with discount code"><LocalOffer /> {cart.short_url_discount}</span> <br /></> : "" }
                                                <LocalOffer /> {cart.cart_discount_code} 
                                                        <Button type="button" onClick={e => {this.NewCode(e)}} className="badge bg-secondary ml-10 border-light" value={cart.id}>
                                                            Generate new
                                                        </Button>  
                                                <br />
                                                {this.state.c_type===1 ? <><span data-tip="No discount link"><Link /> {cart.short_url_no_discount}</span> <br /></> : "" }
                                                <DoneAllOutlined /> {cart.order_recovered===1 ? "Recovered" : "Not recovered"}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                    <Card className="mt-15">
                                        <Card.Body>
                                            <Card.Title>{cart.shop_name}</Card.Title>
                                            <Card.Text>
                                                <Public /> {cart.domain}<br />
                                                <Call /> {cart.shop_phone}<br />
                                                <Email /> {cart.shop_email}<br />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                    <Card className="mt-15 mb-15">
                                        <Card.Body>
                                            <Card.Title>Links</Card.Title>
                                            <Card.Text className="links">

                                                {this.state.pages?.privacy_policy ?
                                                    <p>
                                                        <small>Privacy Policy</small><br/>
                                                        {this.state.pages?.privacy_policy || 'No privacy policy found'}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.return_policy ?
                                                    <p>
                                                        <small>Return Policy</small><br/>
                                                        {this.state.pages?.return_policy || 'No return policy found'}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.exchange_policy ?
                                                    <p>
                                                        <small>Exchange Policy</small><br/>
                                                        {this.state.pages.exchange_policy}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.faq ?
                                                    <p>
                                                        <small>FAQ</small><br/>
                                                        {this.state.pages.faq}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.terms ?
                                                    <p>
                                                        <small>Terms</small><br/>
                                                        {this.state.pages.terms}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.shipping_policy ?
                                                    <p>
                                                        <small>shipping Policy</small><br/>
                                                        {this.state.pages.shipping_policy}
                                                    </p>
                                                : "" }


                                                {this.state.pages?.cookie_policy ?
                                                    <p>
                                                        <small>Cookie Policy</small><br/>
                                                        {this.state.pages.cookie_policy}
                                                    </p>
                                                : "" }

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            </Row>
                        </Col>  
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Chat);

